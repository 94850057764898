body {
  font-family: -apple-system, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin: 0 0 60px 0;
  padding: 0;
}

html {
  min-height: 100%;
  position: relative;
}
