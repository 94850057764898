.Files {
}

.File {
    background-color: white;
    border: 1px solid #337ab7;
    color: #31708f;
    padding: 5px;
    margin-bottom: 20px;
}

.File a {
    color: #337ab7;
    text-decoration: none;
}

.File a:focus,
.File a:hover {
    color: #23527c;
    text-decoration: underline;
}

.File--dir {
    background-color: #d9edf7;
}

.File--dir a {
}

.File--dir a:focus,
.File--dir a:hover {
}
